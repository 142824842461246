@import 'npm:imperavi-kube/src/_scss/variables';

.event__wrapper {
    padding: 75px 30px 60px;

    background: white;

    @media (max-width: $sm) {
        padding: 0;
    }

    main {
        padding: 0 60px 0 30px;

        @media (max-width: $sm) {
            padding: 30px;
        }
    }
}

.event__cover {
    position: relative;

    display: inline-block;

    max-width: 100%;
    margin-bottom: 50px;

    line-height: 0;

    @media (max-width: $sm) {
        margin-bottom: 0;

        &, img {
            width: 100%;
        }
    }
}

.event__title {
    position: absolute;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    padding: 15px 20px;

    color: white;
    background-color: rgba(63, 124, 205, 0.85);

    font-family: Raleway, Arial, Helvetica, sans-serif;
    line-height: normal;

    @media (max-width: $sm) {
        position: relative;

        padding: 30px 0;

        color: inherit;
        background: none;
    }

    h1 {
        margin-bottom: 0;

        letter-spacing: 0;

        color: white;

        font-size: 33px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.29;

        @media (max-width: $sm) {
            color: inherit;
        }
    }
}

.event__schedule {
    margin-bottom: 0;

    white-space: nowrap;
    letter-spacing: 0.4px;

    font-size: 26px;
    font-weight: 700;

    @media (max-width: $sm) {
        white-space: normal;

        font-weight: normal;
    }
}

.event__description {
    hyphens: auto;
}

// Sidebar

.event__wrapper aside {
    padding: 3%;

    background-color: #f2f2f2;

    font-size: 14px;

    @media (max-width: $sm) {
        padding: 30px;
    }

    h2 {
        margin: 0 0 18px;
        padding: 9px 15px;

        color: white;
        background-color: #3f7ccd;

        font-size: 17px;
    }

    dt {
        font-weight: 700;
    }

    dd {
        margin-bottom: 10px;
        margin-left: 0;
        padding-bottom: 10px;
    }
}