@import '../colors';

.footer {
    margin-top: -1px;

    a {
        color: #bfbfbf;
    }
}

.footer__container {
    padding: 50px 0;

    text-align: center;

    color: #9e9b9b;
    background: $gray-dark;

    font-size: 12px;
}