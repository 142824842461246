@import 'npm:imperavi-kube/src/_scss/variables';
@import '../../mixins';

.events__wrapper {
    padding: 75px 30px 60px;

    background: white;

    @media (max-width: $sm) {
        padding: 30px;
    }

    h1 {
        margin-bottom: 60px;

        text-align: center;
        letter-spacing: 0;

        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.29;

        @media (max-width: $sm) {
            margin-bottom: 30px;
        }
    }
}

.events__entry {
    margin-bottom: 44px;

    border: 1px solid #e0dede;

    @include clearfix();

    @media (max-width: $sm) {
        width: 100%;
        margin-bottom: 30px;
    }

    figure {
        float: left;

        width: 50%;
        margin-bottom: 0;

        line-height: 0;

        @media (max-width: $sm) {
            float: none;

            width: 100%;
        }
    }

    h2 {
        margin-bottom: 0;

        font-size: 1.6em;
    }
}

.events__schedule {
    padding: 15px 0 0;

    border-color: #e0dede;

    font-size: 18px;
    font-weight: 700;
}

.events__details {
    float: right;

    width: 50%;
    padding: 1.6em 30px 15px;

    hyphens: auto;

    @media (max-width: $sm) {
        float: none;

        width: 100%;
    }
}

.events__link {
    margin-bottom: 0;
}