@import 'npm:imperavi-kube/src/_scss/variables';
@import '../mixins';

// Intro element

.about-intro {
    margin: 75px 28% 40px;

    hyphens: auto;

    @media (max-width: $sm) {
        margin: 30px;
    }
}

// Mission element

.about-mission {
    @media (max-width: $sm) {
        flex-direction: column-reverse;
    }
}

.about-mission__description {
    padding: 7.5%;

    hyphens: auto;

    background: rgb(236, 236, 236);

    @media (max-width: $sm) {
        padding: 30px;
    }
}

.about-mission__image {
    position: relative;

    img {
        @include cover-bg()
    }

    @media (max-width: $sm) {
        min-height: 400px;
    }
}