@import 'npm:imperavi-kube/src/_scss/variables';
@import '../mixins';

// Intro element

.prayer-intro, .prayer-form {
    margin: 0 15%;

    hyphens: auto;

    @media (max-width: $sm) {
        margin: 30px;
    }
}

.prayer-intro {
    margin-top: 75px;
    margin-bottom: 30px;
}

.prayer-form {
    margin-bottom: 75px;

    h3 {
        margin-top: 0;
        margin-bottom: 0;
    }

    input:not([type="checkbox"]), textarea {
        margin-bottom: 24px;
    }

    .submit {
        text-align: right;
    }

    button {
        margin-top: 12px;
    }
}