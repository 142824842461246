@import 'npm:imperavi-kube/src/_scss/variables';
@import '../mixins';

.hero-image {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 700px;
    max-height: 700px;
    margin: 0;
    padding: 80px;

    @media (max-width: $sm) {
        height: auto;
        min-height: 300px;
        padding: 40px;
    }

    h2 {
        position: relative;
        z-index: 1;

        text-align: center;

        color: #fff;

        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 80px;
        font-weight: 300;
        line-height: 96px;

        @media (max-width: $sm) {
            font-size: 40px;
            line-height: 48px;
        }

        &, p {
            margin-bottom: 0;
        }

        strong {
            font-family: 'League Spartan', 'Raleway', sans-serif;
            font-weight: normal;
        }

        em {
            color: #FF9900;

            font-style: normal;
        }
    }

    img {
        @include cover-bg;
    }

}