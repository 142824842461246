@import '../colors';

body, .container {
    min-height: 100vh;
}

.container {
    display: flex;
    flex-direction: column;

    max-width: 1280px;
    margin: 0 auto;
}

body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
}

main {
    flex: 1;

    background: white;

    .content {
        padding: 40px;
    }
}

.cc-grower {
    background: white;
}