.button {
    padding: 13px 29px;

    user-select: none;
    transition: all .2s;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;

    color: white;
    border-radius: 2px;
    background: #3e7ccc;

    font-family: Open Sans, Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 17px;
}

.button--block {
    display: inline-block;

    width: 100%;

    text-align: center;
}