@import 'npm:imperavi-kube/src/_scss/variables';

.notice {
    margin-bottom: 20px;
    padding: 25px;

    text-align: center;
    text-transform: uppercase;

    color: #808080;
    border: 1px solid #808080;

    @media (max-width: $sm) {
        width: 100%;
    }
}