@import 'npm:imperavi-kube/src/_scss/variables';
@import '../colors';
@import '../mixins';

// Intro paragraph

.intro {
    padding: 90px 15%;

    @media (max-width: $sm) {
        padding: 50px;
    }

    p {
        margin-bottom: 20px;
    }
}

// Next events element

.next-events {
    padding: 90px 8%;

    background-color: #ececec;

    @media (max-width: $sm) {
        padding: 30px;
    }

    h2 {
        text-align: center;
    }
}

.next-events__container {
    display: flex;
    justify-content: space-between;
}

.next-events__event {
    width: 240px;

    background: white;

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: $sm) {
        width: 100%;
        margin-right: 0;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

.next-events__details {
    padding: 20px;
}

.next-events__actions {
    margin-top: 20px;
    margin-bottom: 20px;

    text-align: center;

    .button {
        @media (max-width: $sm) {
            display: block;

            width: 100%;
        }
    }
}

.next-events__title {
    padding-bottom: 10px;

    letter-spacing: 0.5px;

    font-size: 15px;
    line-height: 26px;
}

.next-events__schedule {
    font-size: 14px;
    line-height: 1.5;
}

// New here element

.new-here {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    padding: 15%;

    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;

    @media (max-width: $sm) {
        min-height: 350px;
        padding: 30px;
    }

    h2 {
        z-index: 1;

        margin-bottom: 25px;

        color: white;

        font-size: 50px;
    }

    img {
        z-index: 0;

        @include cover-bg()
    }

    a.button {
        z-index: 1;
    }
}

// Latest photos element

.latest-photos {
    padding: 15%;

    @media (max-width: $sm) {
        padding: 30px;
    }

    h4 {
        margin: 3px 0 15px;

        font-size: 18px;
        line-height: 23px;

        a {
            font-weight: 300;
        }
    }
}

.latest-photos__more {
    display: block;

    padding-top: 9px;
    padding-bottom: 9px;

    letter-spacing: 2px;
    text-transform: uppercase;

    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;

    font-size: 13px;
    font-weight: 600;
}