@import 'npm:imperavi-kube/src/_scss/variables';
@import '../../mixins';

.galleries__wrapper {
    padding: 75px 30px 60px;

    background: white;

    @media (max-width: $sm) {
        padding: 30px;
    }

    h1 {
        margin-bottom: 60px;

        text-align: center;
        letter-spacing: 0;

        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.29;

        @media (max-width: $sm) {
            margin-bottom: 30px;
        }
    }
}

.galleries__entry {
    margin-bottom: 44px;

    border: 1px solid #e0dede;

    @include clearfix();

    @media (max-width: $sm) {
        margin-bottom: 30px;
    }

    figure {
        margin-bottom: 0;

        line-height: 0;
    }

    h2 {
        display: flex;
        align-items: center;
        flex-direction: row;

        margin-top: 0;
        margin-bottom: 0;
        padding: 15px 30px;

        font-size: 1.6em;
    }

    span {
        font-weight: 700;
        flex: 1;
    }

    small {
        font-size: 16px;
    }
}