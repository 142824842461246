@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;

    font-display: block;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(//fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0e.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: italic;

    font-display: block;
    src: local('Raleway Italic'), local('Raleway-Italic'), url(//fonts.gstatic.com/s/raleway/v13/1Ptsg8zYS_SKggPNyCg4TYFv.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 700;
    font-style: italic;

    font-display: block;
    src: local('Raleway Bold Italic'), local('Raleway-BoldItalic'), url(//fonts.gstatic.com/s/raleway/v13/1Ptpg8zYS_SKggPNyCgw9qR_AtCe.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 300;
    font-style: normal;

    font-display: block;
    src: local('Raleway Light'), local('Raleway-Light'), url(//fonts.gstatic.com/s/raleway/v13/1Ptrg8zYS_SKggPNwIYqWqZPBQ.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 400;
    font-style: normal;

    font-display: block;
    src: local('Raleway'), local('Raleway-Regular'), url(//fonts.gstatic.com/s/raleway/v13/1Ptug8zYS_SKggPNyC0ISg.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 600;
    font-style: normal;

    font-display: block;
    src: local('Raleway SemiBold'), local('Raleway-SemiBold'), url(//fonts.gstatic.com/s/raleway/v13/1Ptrg8zYS_SKggPNwPIsWqZPBQ.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-weight: 700;
    font-style: normal;

    font-display: block;
    src: local('Raleway Bold'), local('Raleway-Bold'), url(//fonts.gstatic.com/s/raleway/v13/1Ptrg8zYS_SKggPNwJYtWqZPBQ.ttf) format('truetype');
}

@font-face {
    font-family: League-Spartan;
    font-weight: normal;
    font-style: normal;

    src: url("//freitach4u.msiemens.hadar.uberspace.de/wp-content/uploads/2016/09/leaguespartan-bold.eot?#iefix") format("embedded-opentype"), url("//freitach4u.msiemens.hadar.uberspace.de/wp-content/uploads/2016/09/leaguespartan-bold.woff") format("woff"), url("//freitach4u.msiemens.hadar.uberspace.de/wp-content/uploads/2016/09/leaguespartan-bold.woff2") format("woff2"), url("//freitach4u.msiemens.hadar.uberspace.de/wp-content/uploads/2016/09/leaguespartan-bold.ttf") format("truetype"), url("//freitach4u.msiemens.hadar.uberspace.de/wp-content/uploads/2016/09/leaguespartan-bold.svg") format("svg");
    font-display: block;
}

body {
    letter-spacing: 0.4px;

    color: #747474;

    font-family: Raleway, Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    line-height: 2;
    //hyphens: auto;
}

.numbers {
    font-variant-numeric: oldstyle-nums;
    font-feature-settings: 'onum';
}

strong {
    font-weight: bold;
}

a {
    transition-timing-function: linear;
    transition-duration: .2s;
    transition-property: color, background-color, border-color;
    text-decoration: none;

    color: #333333;

    font-weight: bold;

    &:hover {
        color: #3f7ccd;
    }
}

h2 {
    margin-top: 0;
    margin-bottom: 1.1em;

    letter-spacing: 0;

    color: #333333;

    font-family: Raleway, Arial, Helvetica, sans-serif;
    font-size: 35px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.29;
}

.message {
    text-align: center;

    font-family: Raleway, Arial, Helvetica, sans-serif;
}