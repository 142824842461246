@import 'npm:imperavi-kube/src/_scss/variables';

// Pagination

.pagination {
    overflow: hidden;

    margin: 50px 0 0;
    padding-top: 10px;
    padding-bottom: 10px;

    border-top: 1px solid #ccc;
    border-top-color: rgb(204, 204, 204);
    border-bottom: 1px solid #ccc;
    border-bottom-color: rgb(204, 204, 204);

    @media (max-width: $sm) {
        margin-top: 30px;
    }

    a {
        display: inline-block;

        margin-bottom: 0;
        padding: 0;

        text-decoration: none;

        background: 0 0;

        font-size: 15px;
        font-weight: 400;
    }
}

.pagination__prev {
    float: left;

    margin-left: 10px;
}

.pagination__next {
    float: right;

    margin-right: 10px;
}