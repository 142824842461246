@import 'npm:imperavi-kube/src/_scss/variables';
@import '../colors';

.header {
    padding: 40px 60px;

    border-bottom: 1px solid $gray-light;
    background: white;

    h1 {
        margin-bottom: 0;

        img {
            max-height: 109px;

            @media (min-width: $sm + 1) {
                max-width: 50%;
            }
        }

        @media (max-width: $sm) {
            text-align: center;
        }
    }

    .header__tagline {
        color: $gray;

        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 20px;
        font-weight: 300;
    }
}
