@import 'npm:imperavi-kube/src/_scss/variables';
@import '../colors';

nav {
    position: sticky;
    z-index: 2;
    top: 0;

    height: 50px;

    letter-spacing: 3px;

    border-bottom: 1px solid $gray-light;
    background: white;

    font-size: 13px;
    line-height: 50px;

    &.row {
        flex-direction: row;

        .col {
            width: auto;
        }
    }

    a {
        padding-right: 22.5px;
        padding-left: 22.5px;

        text-decoration: none;
        text-transform: uppercase;

        color: $gray-dark;

        font-size: 14px;
        font-weight: 600;
    }

    li.nav__item--active a, li a:hover, li a:active {
        color: $accent;
    }

    ul.nav__desktop {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }
    }

    .nav__mobile {
        display: none;

        .dropdown.open {
            position: fixed;
            top: 0;
            left: 0;

            width: 100%;
            height: 100vh;
            max-height: inherit;
        }
    }

    @media (max-width: $sm) {
        .nav__desktop {
            display: none;
        }

        .nav__mobile {
            display: block;

            ul {
                clear: both;
            }
        }

        .close {
            float: right;

            margin: 20px;
        }

        .nav__button {
            text-align: center;
        }
    }
}
