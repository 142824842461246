@import 'npm:imperavi-kube/src/_scss/variables';

.gallery__wrapper {
    padding: 75px 30px 60px;

    background: white;

    @media (max-width: $sm) {
        padding: 30px;
    }

    main h1 {
        margin-bottom: 60px;

        letter-spacing: 0;

        font-family: Raleway, Arial, Helvetica, sans-serif;
        font-size: 30px;
        font-weight: 300;
        font-style: normal;
        line-height: 1.29;

        @media (max-width: $sm) {
            margin-bottom: 0;
        }
    }
}

#gallery {
    a {
        display: inline-block;

        width: 32%;
        margin-top: 20px;

        text-align: center;
    }

    img {
        display: block;

        max-height: 66vh;
        margin: 0 auto;
    }
}